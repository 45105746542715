.project-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    img {
        width: calc(250 + 20vw);
        margin-bottom: 30px;
    }
}

.project-description {
    display: flex;
    flex-direction: column;
    margin: auto 0px;

    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 20px;
}

.project-blurb {
    display: inline-block;

    max-width: 100%;

    p {
        margin: 20px;
    }
}

.project-detail {
    display: flex;
    flex-direction: row;
    width: 100%;
    line-height: 35px;
    padding-right: 10px;
}

.project-detail-title {
    display: block;

    width: 95px;
    font-weight: bold;

    
    margin-right: 5px;
    padding-left: 5px;
}

.team-list {
    p {
        margin: 0px;
    }
}

.image-animated {

    position: relative;
    height: calc(200px + 4vw);
    width: calc(400px + 8vw);
    max-width: 90%;
    margin: auto;
    margin-bottom: 10px;

    img {
        height: 100%;
        width: 100%;
        border: 1px solid black;
        object-fit: cover;
    }
    
    .image-animated-overlay {

        position: absolute;
        top: 0px;
        left: 0px;
    
        color: white;
        width: 100%;
        height: 100%;
        
        margin: auto;
        display: flex;
    
        background-color: rgba(0, 0, 0, 0.541);

    }

    .image-animated-play {
        
        display: block;
        margin: auto;
        text-align: center;

        p {
            display: block;
            font-size: calc(30px + 2vmax);
            text-align: center;
            color: rgba(255, 255, 255, 0.712);
            margin: auto;
        }
    }
}

.image-animated-large {
    height: calc(300px + 6vw);
    width: calc(600px + 12vw);
}

.image-animated-small {
    height: calc(160px + 3vw);
    width: calc(320px + 6vw);
}

.image-caption {
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
}

.img-auto-height {
    height: auto;
  }

.pointer {
    cursor: pointer;
}