$page-width: 600px;
$min-page-width: 65vw;
$max-page-width: 100vw;

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

*:focus {
  outline: none;
}

html {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 65%;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  overflow-x: hidden;
  overflow-y: scroll;

  background-color: $dark-gray;
}

.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0px;

  min-height: 100%;
  padding-bottom: 150px;
  position: relative;
}

.page {
  background-color: white;
  min-width: 65vw;
  max-width: 100vw;
  width: 600px;
  min-height: 50vh;

  margin-top: 25px;

  padding: calc(10px + 1.5vmax);
  padding-left: calc(8px + 1.2vw);
  padding-right: calc(8px + 1.2vw);
}

h1 {
  text-align: center;
}

h2 {
  font-size: 22px;
  margin: 0px;
}

h3, h4 {
  margin: 30px 0px 10px 0px;
}

p {
  margin: 0px 0px 10px 0px;
  padding: 0;
}

a {
  color: rgb(155, 155, 253);
  transition: 0.5s color ease;
  &:hover{
    color: $link-blue;
  }
}

ul {
  margin: 0px 0px 20px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.youtube-vid {
  display: block;

  width: calc(400px + 20vw);
  height: calc((400px + 20vw) / 1.33);
  max-width: calc(100vw - 2 * (8px + 1.2vw));
  max-height: calc((100vw - 2 * (8px + 1.2vw)) / 1.33);

  margin: 10px auto;
  padding: 0px;
}

.youtube-vid2 {
  display: block;

  margin: 10px auto;
  border-radius: 10px;

  max-width: 80vw;
}

img, .large-image {
  width: 90%;
}

img {
  display: block;

  width: calc(300px + 20vw);
  max-width: calc(100vw - 2 * (8px + 1.2vw));

  margin: 10px auto;
  padding: 0px;
}

.splash-image {
  border: 1px solid black;
}