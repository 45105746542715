$dark-gray: rgb(29, 29, 29);
$light-gray: rgb(182, 182, 182);
$link-blue: rgb(0, 162, 255);

@import "main.scss";
@import "header.scss";
@import "grid.scss";
@import "accordion.scss";
@import "footer.scss";
@import "vocabulary.scss";
@import "project.scss";
@import "contact.scss";