.footer {
  display: flex;
  flex-direction: column;

  text-align: center;
  vertical-align: middle;

  color: white;
  background-color: $dark-gray;
  width: 100vw;
  height: 150px;

  position: absolute;
  bottom: 0px;

}

.footer-content {
  display: flex;
  flex-direction: row;

  margin: auto;
  min-width: 65vw;
  max-width: 100vw;
  width: 600px;
  background-color: rgb(22, 22, 22);

  padding: 15px;
}

.footer-table {
  text-align: right;
  margin-left: auto;

  td {
    padding-left: 6px;
  }
}