
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(300px + 4vw), 1fr));

  justify-items: center;
}

.grid-item {
  display: block;
  text-align: center;

  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;

  width: calc(100% - 20px);
  height: calc(100% - 20px);

  border-width: 1px;
  border-color: $light-gray;
  border-style: solid;
  border-radius: 3px;

  p {
    margin-left: 10px;
    margin-right: 10px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  transform: scale(1);
  box-shadow: 0px 0px 0px 0px rgb(0, 132, 255);
  transition: 0.2s transform ease, 1s box-shadow ease;
}

.grid-item-hover {
  box-shadow: 0px 0px calc(50px + 1vmax) 0.5px rgba(0, 132, 255, 0.2), 0px 0px 0px 1px rgb(0, 132, 255);
}

.grid-item-down {
  transform: scale(0.98);
}

.grid-image {
  width: 100%;
  height: 250px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  object-fit: cover;
  display: block;
  
  &:before {
    content: '';
    src: url('../Images/other/placeholder.png');
    height: 210px;
    padding: 10px;
    display: block;
    border: 1px;
    border-style: dashed;
    border-color: rgb(94, 94, 94);
    background-color: rgb(197, 197, 197);
    background: url('../Images/other/placeholder.png');
    background-size: auto 100%;
  }
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(200px + 3vw), 1fr));
  
  justify-items: center;

  a {
    color: black;

    &:hover {
      color: $link-blue;
    }
  }
}

.contact-grid-icon {
  display: block;
  font-size: 100px;
}

.contact-grid-title {
  font-weight: bold;
}