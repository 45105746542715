.vocabulary-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    border:0px;
    border-style: solid;

    p {
        margin: 10px;
    }

    form {
        padding: 5px;
    }

    input {
        display: inline-block;
        font-size: 18px;
        padding: 5px;
        margin: 5px;
        //TODO set auto size
    }
    
    .button {
        max-width: 150px;
    }
    
    .question {
        margin: 10px 0px 20px 0px;
        font-size: 20px;
        font-weight: bold;
    }

    .correct-answer {
        color: green;
        font-weight: bold;
    }

    .incorrect-answer {
        color: red;
        font-weight: bold;
    }
}

.test-score {
    text-align: right;

    border: 0px;
    border-color: black;
    border-style: solid;
    
    p {
        font-weight: bold;
    }
}
